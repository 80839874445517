.header-container {
  margin: auto;
  padding: 0 15px;
  width: 90%;
}

.custom-container {
  margin: auto;
  padding: 0 15px;
}

body,
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #f5f6f7;
}
form#mc-embedded-subscribe-form {
  margin: 0;
}

@media screen and (min-width: 576px) {
  .custom-container {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .custom-container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .custom-container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .custom-container {
    max-width: 1140px;
  }
}
@media screen and (min-width: 1500px) {
  .custom-container {
    max-width: 75%;
  }
}

.homepage-hero {
  background-image: url("../images/pngs/heroBg.png");
  background-position: top;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

@media (max-width: 767px) {
  .homepage-hero {
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .homepage-hero {
    background-size: 100%;
  }
}
.dbt-hero {
  background-image: url("../images/pngs/heroBg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.custom-header {
  background: url("../images/pngs/customHeaderbg.png");
  background-size: cover;
}

footer {
  background: url("../images/svgs/footer-bg.svg");
  background-color: white;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.getStarted {
  background: linear-gradient(
    111.49deg,
    #096cd7 1.84%,
    rgba(187, 171, 221, 0.83) 25.73%,
    #793aff 67.69%,
    #c479bc 85.75%,
    #d9c1ff 113.72%
  );
}

.privacy-and-security {
  padding-inline: 10%;
  padding: 5%;
  background: linear-gradient(
    111.49deg,
    #096cd7 1.84%,
    rgba(187, 171, 221, 0.83) 25.73%,
    #793aff 67.69%,
    #c479bc 85.75%,
    #d9c1ff 113.72%
  );
  .privacy-and-security-inner {
    margin: auto;
    padding: 2rem;
    max-width: 1200px;
    background-color: white;
    border-radius: 8px;
    border-bottom: 10px #3f8cff solid;
    .p-c-inner-content {
      display: flex;
      flex-direction: row;
    }
  }
}

.left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: white;
}

.right::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: white;
}

.blog-hero {
  background-image: url("../images/pngs/blogHeroBg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.read-header-bg {
  background-image: url("../images/pngs/read-header-bg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.lh-1 {
  line-height: 1;
}
.social-link {
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
}
a {
  position: relative;
}
/* a.active::after, .nav-item:hover::after {
    content: '';
    width: 20px;
    height: 2px;
    background: white;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
}

*/

.animated-robot {
  animation: jump 3s infinite;
}

.blog-card:hover h2,
.blog-card:hover h3,
.popular .flex:hover h3 {
  color: #096cd7;
}

.blog-card__img {
  overflow: hidden;
  border-radius: 15px;
}

img {
  transition: 0.5s;
}

.blog-card:hover .blog-card__img img {
  transform: scale(1.1);
}

.act {
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
header.fixed {
  transition: 0.5s;
  width: 100%;
  background: white;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 20px 0;
}

/* .team-img img{
    height: 315px;
    object-fit: cover;
    object-position: top;
} */

@keyframes jump {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-30px) scale(1.1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.lh-2 {
  line-height: 1.2;
}

.slider-control-centerleft {
  position: absolute;
  top: -95px;
  right: 60px;
}

.slider-control-centerright {
  position: absolute;
  top: -95px;
  right: 0px;
}
li.paging-item {
  background: rgba(255, 255, 255, 0.5);
  width: 20px;
  height: 20px;
  color: transparent !important;
  border-radius: 50%;
  margin: 0 10px;
}
li.paging-item.active {
  background: white;
}
.paging-item svg {
  display: none;
}

.form-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 10000;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  border: 2px solid white;
}

@media screen and (max-width: 767px) {
  .nav-menu {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75%;
    line-height: 3;
    font-size: 20px;
    transition: 0.6s;
    transform: translateX(-100%);
  }
  .nav-menu.active {
    transform: translateX(0);
  }
  .nav-bg {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.5s;
    transform: translateX(100%);
  }
  .nav-bg.active {
    transform: translateX(0);
  }

  .counter-div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
  }

  .counter-div2 {
    width: 100%;
    padding: 10px;
  }

  .right::before,
  .left::before {
    width: 100%;
  }

  .getStarted {
    text-align: center;
  }
  .newsletter {
    text-align: center;
  }

  .columns img {
    display: none;
  }
  .human-card {
    margin: 20px auto;
  }
  .human-info .h-\[40px\] {
    height: auto;
  }
  .team .custom-container {
    max-width: 75%;
  }
}

.markdown > * {
  all: revert;
}

.document-style {
  margin: auto;
  padding: 80px 15px;
}

.support-cards-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 1.6rem;
  width: 50%;
  left: 25%;
  position: relative;
}

.support-cards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  gap: 1.6rem;
  width: 100%;
}

.support-card {
  border: 1px solid #237efe;
  border-radius: 1rem;
  width: 100%;
  background-color: #f8fbff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  &.not-across {
    height: 20rem;
  }
  &.across {
    margin-inline: 1.2rem;
  }

  &.success-color {
    border: 1px solid #01cd8c;
    background-color: #e6faf4;
  }
}

.support-card .description {
  padding: 1rem;
  color: #374c6a;
  font-size: 0.85rem;
}

.support-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding-top: 1rem;
  padding-left: 1rem;
  gap: 1rem;
  &.no-description {
    padding-top: 0;
  }
}

.support-header h1 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #092247;
}

.support-header img {
  max-height: 3rem;
  max-width: 3rem;
  align-self: flex-start;
}

.across-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.1rem;
}

.across-screen h1 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #092247;
  margin-left: 1rem;
  line-height: 1rem;
}

.support-description {
  font-weight: 100;
  line-height: 1rem;
}

.support-w-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
  width: 100%;
}

.support-w-button.acrossScreen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-w-button p {
  font-size: 1rem;
  color: #092247;
  line-height: normal;
}

.support-button {
  background-color: #237efe;
  color: white;
  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  text-align: center;
  display: block;
  margin: 1.5rem 0;
  text-decoration: none;
  border-radius: 0.5rem;
  font-size: small;
  white-space: nowrap;

  &.success-color {
    background-color: #02cd8c;
  }
}

@media only screen and (max-width: 1550px) {
  .support-cards-main {
    width: 60%;
    left: 20%;
  }
}

@media only screen and (max-width: 1400px) {
  .support-card
    .support-w-button
    .support-header
    .across-screen
    .support-description {
    font-size: 0.95rem;
  }
  .support-card .support-w-button .support-header .across-screen h1 {
    font-size: 1.1rem;
    line-height: 0.1rem;
  }
  .support-card .support-w-button.acrossScreen .support-header {
    gap: 0;
    padding-left: 0;
  }
  .support-card .support-w-button .support-header img {
    align-self: flex-start;
  }
  .support-card .support-w-button .across-btn .support-button {
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .support-cards-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: inherit;
    width: 80%;
    left: 10%;
    position: relative;
    gap: 1.6rem;
  }
  .support-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 1.6rem;
    width: 100%;
  }
  .support-card
    .support-w-button
    .support-header
    .across-screen
    .support-description {
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
  .support-card .support-w-button .support-header .across-screen h1 {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
  .support-card .support-w-button.acrossScreen .support-header {
    gap: 0;
  }
  .support-card .support-w-button .support-header {
    padding-left: 0;
  }
  .support-card .support-w-button .support-header img {
    align-self: flex-start;
  }
  .support-card .support-w-button .across-btn .support-button {
    font-size: 0.5rem;
    white-space: wrap;
  }
}

.ready-get-started {
  background-size: cover;
  height: 500px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ready-get-started h3 {
  font-size: 3rem;
  margin-left: 20%;
}

.below-divider {
  margin-left: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.ready-get-started hr {
  background: white;
  height: 1px;
  border: none;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-block: 1.5rem;
}

.ready-get-started button {
  background-color: #02cd8c;
  color: white;
  padding: 0.55rem;
  text-align: center;
  display: block;
  margin: 1.5rem 0;
  text-decoration: none;
  border-radius: 0.5rem;
  min-width: 10rem;
  font-size: small;
}

@media only screen and (max-width: 768px) {
  .card {
    width: 90%;
  }

  .ready-get-started h3 {
    font-size: 1.5rem;
    margin-left: 5%;
  }

  .below-divider {
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    font-size: 0.55rem;
  }

  .ready-get-started hr {
    background: white;
    height: 1px;
    border: none;
    margin-left: 5%;
    width: 90%;
    margin-top: 1rem;
  }

  br {
    display: none;
  }

  .ready-get-started button {
    background-color: #02cd8c;
    color: white;
    padding: 0.25rem;
    text-align: center;
    display: block;
    margin: 0.15rem 0;
    text-decoration: none;
    border-radius: 0.15rem;
    font-size: xx-small;
  }
}

table {
  border-collapse: separate;
  border: 2px dashed #d3d8e0;
  border-radius: 0.5rem;
}

td,
th {
  border-left: 2px dashed #d3d8e0;
  border-top: 2px dashed #d3d8e0;
  padding: 1rem;
  text-align: left;
  background-color: #f6f7f9;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: small;
}

th {
  border-top: none;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  font-size: small;
  background-color: #f3f4f6;
}

td:first-child,
th:first-child {
  border-left: none;
}

.price-info-tooltip {
  max-width: 25rem;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
}

.rodal-dialog {
  background: linear-gradient(
    258.18deg,
    #00265c -4.68%,
    #001635 37.21%,
    #011025 101.15%
  ) !important ;
  width: 50% !important;
  height: 35% !important;
  border-radius: 1rem !important;
  padding: 0 !important;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 2rem;
  gap: 2rem;

  .input-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    max-width: 50%;

    .heading {
      font-size: 1.5rem;
    }

    .input-text {
      font-size: 1rem;
      &.error {
        font-size: 0.6rem;
        color: red;
      }
    }

    .email-input-container {
      background-color: white;
      border-radius: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.25rem;

      .email-input {
        color: black;
        border: none;
        padding: 0.25rem;
        width: 100%;
        font-size: inherit;
      }

      .email-input-button {
        background-color: #247efe;
        border-radius: 0.5rem;
        color: white;
        padding-inline: 1.25rem;
        padding-block: 0.35rem;
        &:focus {
          outline: none;
        }
        &.disabled {
          cursor: not-allowed;
          background-color: #004fbf;
        }
      }
    }

    .back-to-sign-up-button {
      background-color: #247efe;
      border-radius: 0.5rem;
      color: white;
      padding-inline: 1.25rem;
    }
  }

  .modal-image {
    max-width: 50%;
    max-height: 100%;
    object-fit: contain;
  }
}

.rodal-close {
  background: white !important;
  color: black !important;
  padding: 0.5rem;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

@media screen and (max-width: 768px) {
  .rodal-dialog {
    width: 80% !important;
  }
  .rodal-close {
    padding: 0.75rem;
  }
  .modal-content {
    padding: 0rem;
    .input-content {
      max-width: 80%;
      .input-text {
        font-size: 1rem;
      }
      .email-input-container {
        flex-direction: column;
        .email-input {
          height: 3.5rem;
          /* font-size: 1rem; */
        }
        .email-input-button {
          padding: 1;
          min-width: 100%;
        }
      }
    }
  }

  .modal-image {
    display: none;
  }
}

.snowflake {
  display: flex;
  flex-direction: column;
  align-items: center;
  .snowflake-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 6rem;
  }
  .cost-savings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    img {
      margin-top: 10px;
      margin-bottom: -9rem;
      margin-left: 10rem;
    }
    .analytics {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0rem;
      img {
        margin-bottom: -4rem;
        margin-left: 4rem;
      }
    }
  }

  .warehouse-insights {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .insights-text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .fixes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .recommendations {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 5rem;
  }
}

.snowflake-info-box {
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(255, 206, 115, 0.1);
  border: 1px solid #ffce73;
  border-radius: 12px;
  width: fit-content;
}

.product-demo {
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 2.5rem;
  gap: 15rem;

  .heading-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .heading {
    font-size: 2rem;
    font-weight: 600;
  }

  button {
    background-color: #02cd8c;
    color: white;
    padding: 1rem;
    text-align: center;
    display: block;
    margin: 1.5rem 0;
    text-decoration: none;
    border-radius: 0.25rem;
    min-width: 10rem;
    font-size: medium;
    height: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .snowflake {
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align: center;
    .snowflake-text {
      margin-left: 0;
    }
    .cost-savings {
      img {
        margin-bottom: -3.5rem;
      }
      .analytics {
        align-items: center;
        align-content: center;
        justify-content: center;
        img {
          margin-bottom: 0;
          margin-left: 2rem;
        }
      }
    }
    .recommendations {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .snowflake-info-box {
    width: 90%;
  }

  .product-demo {
    flex-direction: column;
    gap: 0;
    .heading-content {
      width: 90%;
      text-align: center;
    }
  }
}

.partner-user-companies {
  margin-inline: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  img {
    user-select: none;
  }
}

.user-companies {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f7f9;
}

.partner-companies {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.logos {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .logo-container {
    padding-inline: 10%;
    flex-direction: row;
  }

  .logos {
    flex-direction: column;
  }
}

.homepage-1 {
  padding-inline: 350px;
  padding-top: 150px;
  padding-bottom: 120px;
  background: url("../images/svgs/home-background.svg");
  background-size: cover;

  .developement-box {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #e9e9ff;
    box-shadow: 20px 38px 35px rgba(8, 34, 71, 0.02);
    border-radius: 28px;
    padding-block: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: 76px;
    margin-top: 85px;

    .developement-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 3.5rem;
      padding-block: 20px;
    }

    .developement-first-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      .developement-logos {
        height: 1.2em;
        opacity: 0;
        animation: fadeIn 1s forwards;
      }
    }
  }

  .feature-boxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
  }
  .smallboxes-arrow {
    margin-top: -0.5rem;
  }
  .feature-boxes-top {
    margin-top: 25px;
    position: absolute;
  }
  .feature-boxes-bottom {
    margin-top: -120px;
    position: absolute;
  }

  .small-boxes {
    display: flex;
    justify-content: center;
  }
  .small-boxes-top {
    margin-left: -2.5%;
    margin-top: 30px;
  }
  .small-boxes-bottom {
    margin-left: -45%;
    margin-top: -1%;
  }

  .developement-small-box {
    border-radius: 8px;
    border: 1px solid #fafaff;
    box-shadow: 2px 8px 15px rgba(8, 34, 71, 0.1);
    background: rgba(255, 255, 255);
    padding-inline: 50px;
    padding-block: 18px;
    color: #082247;
    transition: 0.25s;
    font-size: 1rem;
    white-space: nowrap;
  }
  .developement-small-box-top {
    cursor: pointer;
    border: 1px solid #3f8cff;
    &:hover {
      background-color: #f5f8ff;
      transition: 0.25s;
      box-shadow: 8px 8px 15px rgba(8, 34, 71, 0.1);
      color: #3f8cff;
    }
  }
}

@media screen and (min-width: 2200px) {
  .homepage-1 {
    padding-inline: 550px;
    .developement-box {
      .developement-text {
        font-size: 4.5rem;
      }
    }
    .feature-boxes-bottom {
      img {
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .homepage-1 {
    padding-inline: 150px;
    padding-block: 125px;
    .developement-box {
      padding-top: 50px;
    }
    .developement-small-box {
      padding-inline: 20px;
      padding-block: 18px;
    }
  }
}

@media screen and (max-width: 900px) {
  .homepage-1 {
    padding: 10%;
    padding-top: 25%;
    background: url("../images/svgs/home-background-mobile.svg");
    .developement-box {
      min-width: 100%;
      margin-left: 0;
      padding-block: 5%;
      margin-inline: 0;
      margin-inline: 0;
      margin-top: 0;
      .developement-text {
        font-size: 2rem;
      }
    }
    .feature-boxes-top {
      margin-bottom: 0;
    }
    .feature-boxes-bottom {
      margin-left: 0;
      margin-top: 0;
    }
    .developement-small-box {
      border-radius: 6px;
      padding: 3px;
      margin: 3px;
      white-space: nowrap;
      margin-inline: -0.1rem;
    }
    .arrow-down {
      margin-top: -2rem;
    }
    .dev-mobile {
      margin-top: 20px;
    }
    .dev-top-line-2 {
      display: flex;
      justify-content: center;
      margin-top: -14px;
    }
    .dev-mobile-2 {
      margin-bottom: 30px;
    }
    .dev-top-line-3 {
      display: flex;
      justify-content: center;
      margin-bottom: -17px;
    }
  }
}

.product-workflow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 70px;
  padding-block: 5%;
  /* padding-inline: 10%; */
}
.product-workflow-straight {
  flex-direction: row;
  background-color: white;
}
.product-workflow-reverse {
  flex-direction: row-reverse;
}

.product-text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 540px;
  max-width: 540px;

  .product-options {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 24px;

    .product-option {
      padding-inline: 1rem;
      padding-block: 0.5rem;
      border-radius: 6px;
      cursor: pointer;
    }
    .selected-option {
      background-color: #408bff;
      color: white;
    }
    .unselected-option-1 {
      background-color: #f5f8ff;
      color: #8390a3;
      &:hover {
        background-color: #408bff;
        color: white;
      }
    }
    .unselected-option-2 {
      background-color: white;
      color: #8390a3;
      &:hover {
        background-color: #408bff;
        color: white;
      }
    }
  }
}
.product-text-content-reverse {
  flex-basis: 50%;
}

@media screen and (max-width: 900px) {
  .product-workflow {
    flex-direction: column;
    gap: 2rem;
    padding: 5%;
  }
  .product-text-content {
    align-items: center;
    text-align: center;
    padding-inline: 20%;
  }
}

.workflow-2-text-1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .workflow-2-text-1-arrow {
    height: 1.5em;
  }
}

.data-pilot-agent {
  display: flex;
  flex-direction: column;
  padding-inline: 20%;
  padding-top: 5%;
}

.data-pilot-actions {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-block: 5%;
  padding-inline: 20%;
  gap: 2rem;
  .data-pilot-action-imgs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
  }
  .upcoming-features {
    background: linear-gradient(
      111.49deg,
      #096cd7 1.84%,
      rgba(187, 171, 221, 0.83) 25.73%,
      #793aff 67.69%,
      #c479bc 85.75%,
      #d9c1ff 113.72%
    );
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 90%;
    border-radius: 1rem;
    .inner-text {
      color: white;
    }
  }
}

@media screen and (max-width: 900px) {
  .data-pilot-agent {
    padding-inline: 2.5%;
  }
}

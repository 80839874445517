.homepage {
  padding-top: 150px;
  padding-bottom: 120px;
  background: url("../../assets/images/svgs/home-background.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700;900&display=swap');

* {
  font-family: "Lexend", sans-serif;
  font-weight: 400;
}


.source-serif{
  font-family: 'Source Serif Pro', serif;
}